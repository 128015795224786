import { ApolloLink, HttpLink, from } from "@apollo/client";
import { onError } from "@apollo/client/link/error";

export enum GraphqlVersion {
  V2 = "v2",
}

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  const queryName = operation.operationName || "Unnamed query";

  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) => {
      console.error(`[GraphQL Error]: Query: ${queryName}, Message: ${message}`);
    });
  }

  if (networkError) {
    console.error(`[Network error] Query: ${queryName}, Message: ${networkError.message}`);
    console.error(JSON.stringify(networkError));
  }
});

const withBaseLink = (link: HttpLink) => {
  return from([errorLink, link]);
};

const apolloHttpLinkBase = {
  credentials: "include",
};

const ssrV2Link = withBaseLink(
  new HttpLink({
    ...apolloHttpLinkBase,
    uri: process.env.NEXT_PUBLIC_SSR_GRAPHQL_V2_URL,
  }),
);

const ssrV1Link = withBaseLink(
  new HttpLink({
    uri: process.env.NEXT_PUBLIC_SSR_GRAPHQL_URL,
  }),
);

export const ApolloServerSideLink = ApolloLink.split(
  (operation) => operation.getContext().api === GraphqlVersion.V2,
  ssrV2Link,
  ssrV1Link,
);

export const ApolloAppRouterLink = ApolloLink.split(
  (operation) => operation.getContext().api === GraphqlVersion.V2,
  ssrV2Link,
  ssrV1Link,
);

const frontV2Link = withBaseLink(
  new HttpLink({
    ...apolloHttpLinkBase,
    uri: process.env.NEXT_PUBLIC_GRAPHQL_V2_URL,
  }),
);

const frontV1Link = withBaseLink(
  new HttpLink({
    uri: process.env.NEXT_PUBLIC_GRAPHQL_URL,
  }),
);

export const ApolloProviderLink = ApolloLink.split(
  (operation) => operation.getContext().api === GraphqlVersion.V2,
  frontV2Link,
  frontV1Link,
);
